import { AlertColor } from "@mui/material"
import ActionNotification, {
  ActionNotificationProps,
} from "components/common/actionNotification/ActionNotification"
import React, { useState } from "react"
import { createContext } from "react"

export type ActionNotificationContextValeurType = {
  displayAlert: (message: string, severity?: AlertColor, open?: boolean) => void
}

export const ActionNotificationContext = createContext<
  ActionNotificationContextValeurType | undefined
>(undefined)

const ActionNotificationContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [openActionNotification, setOpenActionNotification] =
    useState<Omit<ActionNotificationProps, "handleClose">>()

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }

    setOpenActionNotification((oldValue) => ({
      ...oldValue,
      open: false,
    }))
  }

  const showActionNotification: ActionNotificationContextValeurType["displayAlert"] =
    (message, severity = "success", open = true) => {
      setOpenActionNotification({
        open,
        message,
        severity,
      })
    }

  return (
    <ActionNotificationContext.Provider
      value={{
        displayAlert: showActionNotification,
      }}>
      <ActionNotification
        {...openActionNotification}
        handleClose={handleClose}
      />
      {children}
    </ActionNotificationContext.Provider>
  )
}

export default ActionNotificationContextProvider

import { Route, Routes } from "react-router-dom"
import NotFoundPage from "views/404/NotFoundPage"
import Activity from "views/activity/Activity"
import ClosedAssignment from "views/assignment/ClosedAssignment"
import Logout from "views/auth/Logout"
import Client from "views/clients/Client"
import Dashboard from "views/dashboard/Dashboard"
import AddClient from "views/dashboard/views/AddClient"
import Home from "views/home/Home"
import Invoices from "views/invoices/Invoices"
import Offer from "views/offer/Offer"
import StatisticalReport from "views/report/StatisticalReport"
import Setting from "views/setting/Setting"

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}>
        <Route index element={<Dashboard />} />
        <Route path="ajout-client" element={<AddClient />} />
      </Route>
      <Route path="/clients" element={<Client />} />
      <Route path="/offres" element={<Offer />} />
      <Route path="/factures" element={<Invoices />} />
      <Route path="/activites" element={<Activity />} />
      <Route path="/affectations-closes" element={<ClosedAssignment />} />
      <Route path="/rapports-statistiques" element={<StatisticalReport />} />
      <Route path="/parametres" element={<Setting />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes

import ViewLayout from "layout/viewLayout/ViewLayout"

export type SettingProps = {}

const Setting = (props: SettingProps) => {
  return (
    <ViewLayout>
      <div>
        <h1>Setting</h1>
      </div>
    </ViewLayout>
  )
}

export default Setting

import ViewLayout from "layout/viewLayout/ViewLayout"

export type ActivityProps = {}

const Activity = (props: ActivityProps) => {
  return (
    <ViewLayout>
      <h1>Activity</h1>
    </ViewLayout>
  )
}

export default Activity

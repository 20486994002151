import SelectUnstyled, { SelectOption, selectUnstyledClasses, SelectUnstyledProps } from "@mui/base/SelectUnstyled"
import OptionUnstyled, { optionUnstyledClasses } from "@mui/base/OptionUnstyled"
import { styled } from "@mui/system"
import { ForwardedRef, forwardRef, RefAttributes } from "react"

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
}

const grey = {
  50: "#F9F9F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CCE4EA",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
}

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: Roboto, sans-serif;
	width: 100%;
	height: 40px;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color:  ${grey[900]};
  background: ${grey[50]};
  border: 1px solid ${grey[300]};
  border-radius: 0.25rem;
  padding: 6px 12px;
  text-align: left;

  &:hover {
    background: ${grey[100]};
    border-color: ${grey[400]};
  }

	&:focus {
      outline: 3px solid ${blue[100]};
    }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${blue[100]};
  }
  
  .MuiSelectUnstyled-popper {
    z-index: 2 !important;
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `,
)

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Roboto, sans-serif;
  font-size: 0.8rem;
  box-sizing: border-box;
  padding: 0 5px;
  margin: 10px 0;
  min-width: 320px;
  width: 100%;
  background: ${"#fff"};
  border: 1px solid ${grey[300]};
  border-radius: 0.25rem;
  color: ${grey[900]};
  overflow: auto;
  outline: 0px;
  `,
)

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  font-family: Roboto, sans-serif;
	list-style: none;
  padding: 8px;
  border-radius: 0.25rem;
  margin: 5px 0;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${blue[100]};
    color: ${blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${grey[100]};
    color: ${grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${blue[100]};
    color: ${blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${grey[100]};
    color: ${grey[900]};
  }
  `,
)

const CustomSelect = forwardRef(function CustomSelect<TValue>(
  props: SelectUnstyledProps<TValue>,
  ref: ForwardedRef<HTMLUListElement>,
) {
  const components: SelectUnstyledProps<TValue>["components"] = {
    Root: StyledButton,
    Listbox: StyledListbox,
    /* Popper: StyledPopper, */
    ...props.components,
  }

  return <SelectUnstyled {...props} ref={ref} components={components} />
}) as <TValue>(
  props: SelectUnstyledProps<TValue> & RefAttributes<HTMLUListElement>,
) => JSX.Element

const renderValue = (
  option: SelectOption<any> | null,
  placeholder?: string,
) => {
  if (option == null) {
    return <span>{placeholder}</span>
  }

  return <span>{option.label}</span>
}

export type MuiSelectProps<TValue = {}> = {
  noOptionsText?: string
  placeholder?: string
  label?: string
  selectProps?: SelectUnstyledProps<TValue>
  options: { value: number | string; label: number | string; disabled?: boolean }[]
}

const MuiSelect = ({
                     label,
                     options,
                     selectProps,
                     placeholder = "Selectionnez une option...",
                     noOptionsText = "Aucune option",
                   }: MuiSelectProps) => {
  return (
    <>
      {label && <label className="font-medium">{label}</label>}
      <CustomSelect
        {...selectProps}
        renderValue={(option) => renderValue(option, placeholder)}
        className={`mt-2 mb-4 ${selectProps?.className}`}>
        {options.length === 0 ? (
          <StyledOption disabled value={""}>
            {noOptionsText}
          </StyledOption>
        ) : (
          options.map(({ value, label, disabled }, index) => (
            <StyledOption key={index} value={value} disabled={disabled}>
              {label}
            </StyledOption>
          ))
        )}
      </CustomSelect>
    </>
  )
}

export default MuiSelect

import { ErrorMessage } from "@hookform/error-message"
import MuiInput from "components/common/muiInput/MuiInput"
import MuiSelect from "components/common/muiSelect/MuiSelect"
import { Controller, UseFormReturn } from "react-hook-form"
import { AllStepFormType } from "types/StepForm"
import { EnterpriseFormFieldNameEnum } from "types/EnterpriseForm"
import DragAndDrop from "../../../components/common/dragAndDrop/DragAndDrop"
import PhoneInput, { handleValidate } from "../../../components/common/phoneInput/PhoneInput"
import { useState } from "react"

export type StepEnterpriseProps = Omit<UseFormReturn<AllStepFormType>,
  "handleSubmit" | "reset">

const StepEnterprise = (props: StepEnterpriseProps) => {
  const [countryCode, setCountryCode] = useState("bj")

  const legal_form = [
    { label: "Société à reponsabilité limitée", value: "SARL" },
    { label: "Société anonyme", value: "SA" },
    { label: "Entreprise individuelle", value: "EI" },
    { label: "Entreprise unipersonnelle à responsabilité limitée", value: "EURL" },
    { label: "Société par actions simplifiée (SAS) ou société par actions simplifiée unipersonnelle", value: "SASU" },
    { label: "Société en nom collectif", value: "SNC" },
    { label: "Société coopérative de production", value: "SCOP" },
    { label: "Société en commandite par actions", value: "SCA" },
    { label: "Société en commandite par actions", value: "SCA" },
    { label: "société en commandite simple", value: "SCS" },
  ]
  const {
    control,
    formState: { errors },
  } = props

  return (
    <div className="bg-white grid grid-cols-8 py-8">
      <div className="col-span-6 col-start-2">
        <DragAndDrop inputProps={{ name: EnterpriseFormFieldNameEnum.LOGO }}
                     placeholders={{ whyText: "Sélectionnez le logo", btnText: "Importer" }}
                     errorMessage="Ajoutez un logo pour l'entreprise." />
        <>
          <Controller
            name={EnterpriseFormFieldNameEnum.IFU}
            control={control}
            rules={{
              required: "Veuillez renseigner un IFU valide",
              validate: (value: string) => value.replaceAll(/[_*\s]/g, "").trim().length == 13 || "Veuillez renseigner un IFU valide",
            }}
            render={({ field }) => (
              <MuiInput
                label="Identifiant Fiscal Unique (IFU) *"
                inputMask={{
                  placeholder: "0 999 9999 9999",
                  type: "text",
                  mask: "9 9999 9999 9999",
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value.replaceAll(" ", "")),
                }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={EnterpriseFormFieldNameEnum.IFU}
            as="p"
            className="text--error"
          />
        </>

        <>
          <Controller
            name={EnterpriseFormFieldNameEnum.PROFESSIONAL_RISK}
            control={control}
            rules={{
              required: "Entrez une valeur numérique pour le risque professionel",
              validate: (value: string) => 0 < Number(value) && Number(value) < 5 || "Veuillez renseigner une valeur comprise entre 1 et 4",
            }}
            render={({ field }) => (
              <MuiSelect
                selectProps={{ ...field, value: field.value ?? "" }}
                label="Risque Professionnel *"
                options={[...Array(4).keys()].map(x => ({ label: x + 1, value: x + 1 }))}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={EnterpriseFormFieldNameEnum.PROFESSIONAL_RISK}
            as="p"
            className="text--error"
          />
        </>

        <>
          <Controller
            name={EnterpriseFormFieldNameEnum.COMPANY_NAME}
            control={control}
            rules={{
              required: "Le nom de l'entreprise est requise.",
            }}
            render={({ field }) => (
              <MuiInput
                label="Nom de votre entreprise (raison sociale) *"
                inputProps={{
                  placeholder: "ERUGIS CONSULTING",
                  type: "text",
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={EnterpriseFormFieldNameEnum.COMPANY_NAME}
            as="p"
            className="text--error"
          />
        </>

        <>
          <Controller
            name={EnterpriseFormFieldNameEnum.ADDRESS}
            control={control}
            rules={{
              required: "L'adresse de l'entreprise est requise.",
            }}
            render={({ field }) => (
              <MuiInput
                label="Adresse *"
                inputProps={{
                  placeholder: "Calavi ABOMEY-CALAVI, 7ième Rue à droite après carrefour IITA",
                  type: "text",
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={EnterpriseFormFieldNameEnum.ADDRESS}
            as="p"
            className="text--error"
          />
        </>

        <>
          <Controller
            name={EnterpriseFormFieldNameEnum.LEGAL_FORM}
            control={control}
            render={({ field }) => (
              <MuiSelect
                selectProps={{ ...field, value: field.value ?? "" }}
                label="Forme jurique"
                options={legal_form}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="juridic_form"
            message="Valeur requise"
            as="p"
            className="text--error"
          />
        </>

        <>
          <Controller
            name={EnterpriseFormFieldNameEnum.COLLECTIVE_AGREEMENT}
            control={control}
            render={({ field }) => (
              <MuiSelect
                selectProps={{ ...field, value: field.value ?? "" }}
                label="Convention collective"
                placeholder="Type de convention"
                options={[
                  {
                    label: "Convention collective générale du bénin",
                    value: "Convention collective générale du bénin",
                  },
                ]}
              />
            )}
          />
        </>

        <>
          <label className="font-medium mb-4">
            Contact *
          </label>
          <br />
          <Controller
            name={EnterpriseFormFieldNameEnum.CONTACT}
            control={control}
            rules={{
              required: "Veuillez renseigner votre numéro de téléphone",
              validate: (value) => handleValidate(value, countryCode) || "Le numéro n'est pas valide pour le pays sélectionné",
            }}
            render={({ field }) => (
              <>
                <PhoneInput
                  defaultCountry={"bj"}
                  isCodeEditable={false}
                  onChange={(value, country) => {
                    setCountryCode(country.countryCode)
                    field.onChange(value)
                  }} value={field.value} />
              </>)
            }
          />
          <ErrorMessage
            errors={errors}
            name={EnterpriseFormFieldNameEnum.CONTACT}
            as="p"
            className="text--error"
          />
        </>
        <br />
        <>
          <Controller
            name={EnterpriseFormFieldNameEnum.EMAIL}
            control={control}
            rules={{
              required: "L'email est requis.",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entrez un email valid",
              },
            }}
            render={({ field }) => (
              <MuiInput
                label="Email Entreprise *"
                inputProps={{
                  placeholder: "info@gmail.com",
                  type: "email",
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={EnterpriseFormFieldNameEnum.EMAIL}
            as="p"
            className="text--error"
          />
        </>
        <>
          <Controller
            name={EnterpriseFormFieldNameEnum.ADMIN_EMAIL}
            control={control}
            rules={{
              required: "L'email est requis.",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entrez un email valid",
              },
            }}
            render={({ field }) => (
              <MuiInput
                label="Email Connexion Entreprise *"
                inputProps={{
                  placeholder: "admin@gmail.com",
                  type: "email",
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={EnterpriseFormFieldNameEnum.ADMIN_EMAIL}
            as="p"
            className="text--error"
          />
        </>
      </div>
    </div>
  )
}

export default StepEnterprise

import { usePricingPlans } from "api/StepForm"
import {
  FirstPricingData,
  FromAPIPricingPLansData,
  PricingOptionDummyData,
  SecondPricingData,
  ThirdPricingData,
} from "assets/dummyData"
import Pricing from "components/common/pricing/Pricing"
import {
  choosedPricingOptions,
  totalFromPricingOptions,
  transformAPIDataToPricingPlangData,
} from "helpers/stepForm"
import { useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { PricingPlansResponseType } from "types/API"
import {
  PricingFormFieldNameEnum,
  PricingItemType,
  PricingTitle,
} from "types/PricingForm"
import { AllStepFormType } from "types/StepForm"

export type StepPricingProps = Omit<
  UseFormReturn<AllStepFormType>,
  "handleSubmit" | "reset"
>

const StepPricing = (props: StepPricingProps) => {
  const {
    setValue,
    register,
    formState: { errors },
  } = props

  const [selectedPricing, setSelectedPricing] = useState("")

  const handlePricingClick = (
    _pricingTitle: PricingTitle,
    id: string,
    pricingOptions: PricingItemType[]
  ) => {
    setValue(`${PricingFormFieldNameEnum.PLAN}`, id)
    setSelectedPricing(id)
    setValue("chosen_options", choosedPricingOptions(pricingOptions))
  }

  const { plans, isLoading, error } = usePricingPlans()
  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Une erreur est survenue!</div>

  const plansData = transformAPIDataToPricingPlangData(plans)

  /* const plansData = transformAPIDataToPricingPlangData(
    FromAPIPricingPLansData as PricingPlansResponseType[]
  ) */

  return (
    <>
      <div className=" grid grid-cols-10">
        <div className="bg-white col-span-10 flex flex-col justify-center items-stretch md:flex-row md:justify-around p-8">
          {plansData.map((plan, index) => (
            <div key={plan.title} className="flex">
              <input
                {...register(PricingFormFieldNameEnum.PLAN, {
                  required: true,
                })}
                type="radio"
                value={plan.id}
                name={PricingFormFieldNameEnum.PLAN}
                id={plan.id}
                className="hidden"
              />
              <label htmlFor={plan.id}>
                <Pricing
                  isSelected={selectedPricing === plan.id}
                  id={plan.id}
                  title={plan.title}
                  subTitle={plan.subTitle}
                  description={plan.description}
                  handlePricingChoice={handlePricingClick}
                  items={plan.items}
                />
              </label>
            </div>
          ))}
        </div>
        {/* <ErrorMessage
          errors={errors}
          message="Choisissez un pricing"
          name="pricing"
          as="p"
          className="text--error"
        /> */}
      </div>
    </>
  )
}

export default StepPricing

import { MdOutlineArrowUpward } from "react-icons/md"

export type CardWithStatisticProps = {
  total: string | number
  title: string
  quantity: string | number
}

const CardWithStatistic = ({
  total,
  title,
  quantity,
}: CardWithStatisticProps) => {
  return (
    <div className="bg-white shadow-sm px-5 lg:px-7 py-2 lg:py-4">
      <div className="flex justify-between">
        <p className="font-bold text-2xl lg:text-4xl up-text-green-2 !leading-[0px]">
          {total}
        </p>
        <div className="flex items-center ">
          <p className="up-text-gray text-base lg:text-xl lg:leading-6 !leading-[0px]">
            {quantity}
          </p>
          <MdOutlineArrowUpward color="#399AB3" />
        </div>
      </div>
      <p className="up-link-inactive text-sm lg:text-lg !leading-[0px]">
        {title}
      </p>
    </div>
  )
}
/* const CardWithStatistic = () => {
  return (
    <div className="bg-white shadow-sm px-7 py-3">
      <div>
        <h1>1248</h1>
        <p>
          <span>12</span>
          <MdOutlineArrowUpward color="#979797" />
        </p>
      </div>
      <p>Contrat valide</p>
    </div>
  )
} */

export default CardWithStatistic

import { KEYCLOAK_AUTH_ACCESS_CLIENT_ID } from "../constants/api/endpoints"
import { KEYCLOAK_AUTH_ACCESS_REALM, KEYCLOAK_BASE_URL } from "constants/api/endpoints"
import Keycloak from "keycloak-js"

//keycloak initialization options
const keycloak = new Keycloak({
  url: KEYCLOAK_BASE_URL,
  realm: KEYCLOAK_AUTH_ACCESS_REALM,
  clientId: KEYCLOAK_AUTH_ACCESS_CLIENT_ID,
})

export default keycloak

import { Link, LinkProps } from "react-router-dom"
import LabelWithIcon, {
  LabelWithIconProps,
} from "../labelWithIcon/LabelWithIcon"

export type LinkWithIconProps = {
  linkProps: LinkProps
} & LabelWithIconProps

const LinkWithIcon = ({ linkProps, ...other }: LinkWithIconProps) => {
  return (
    <Link {...linkProps}>
      <LabelWithIcon {...other} />
    </Link>
  )
}

export default LinkWithIcon

import StepEnterprise, {
  StepEnterpriseProps,
} from "views/dashboard/components/StepEnterprise"
import StepLegalPerson from "views/dashboard/components/StepLegalPerson"
import StepPricing from "views/dashboard/components/StepPricing"

export type StepComponentsProps = StepEnterpriseProps & {
  activeStep: number
}

const StepComponents = ({
  activeStep,
  ...formActions
}: StepComponentsProps) => {
  switch (activeStep) {
    case 0: {
      return <StepEnterprise {...formActions} />
    }

    case 1: {
      return <StepLegalPerson {...formActions} />
    }

    case 2: {
      return <StepPricing {...formActions} />
    }

    default: {
      return <></>
    }
  }
}

export default StepComponents

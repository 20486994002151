// Keycloak
export const KEYCLOAK_BASE_URL = `${import.meta.env.VITE_KEYCLOAK_BASE_URL}`

export const KEYCLOAK_AUTH_ACCESS_CLIENT_ID = `${import.meta.env.VITE_KEYCLOAK_AUTH_ACCESS_CLIENT_ID}`
export const KEYCLOAK_AUTH_ACCESS_GRANT_TYPE = `${import.meta.env.VITE_KEYCLOAK_AUTH_ACCESS_GRANT_TYPE}`
export const KEYCLOAK_AUTH_ACCESS_REALM = `${import.meta.env.VITE_KEYCLOAK_AUTH_ACCESS_REALM}`

export const KEYCLOAK_AUTH_TOKEN_KEY = "KEYCLOAK_AUTH_TOKEN_KEY"

// Client Service Endpoints
export const CLIENT_SERVICE_BASE_URL = `${import.meta.env.VITE_CLIENT_SERVICE_BASE_URL}`
export const FETCH_PLANS_URL = CLIENT_SERVICE_BASE_URL + "plans/"
export const GET_CREATE_COMPANIES_URL = CLIENT_SERVICE_BASE_URL + "companies/"
export const GET_ADD_REPRESENTATIVES_URL = GET_CREATE_COMPANIES_URL + ":id/representatives/"
export const GET_ADD_SUBSCRIPTION_URL = GET_CREATE_COMPANIES_URL + ":id/subscriptions/"
export const COMPANY_INVITATION_REQUEST_URL = CLIENT_SERVICE_BASE_URL + 'companies/:id/invite/'
export const LIST_SUBSCRIPTION_URL = CLIENT_SERVICE_BASE_URL + "subscriptions/"


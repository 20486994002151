import { Checkbox, CheckboxProps } from "@mui/material"
import React, { useState } from "react"
import { IconBaseProps } from "react-icons"
import { MdCancel, MdCheckCircle } from "react-icons/md"
import { HTMLInputEventProps } from "types/HTMLComponentsProps"
import { PricingItemType } from "types/PricingForm"

export type PricingOptionProps = {
  label: string
  status?: PricingItemType["status"]
  iconProps?: IconBaseProps
  checkboxProps?: {
    checked: boolean
    /* onChange: (e: HTMLInputEventProps, pos: number) => void */
    onChange: (pricingOption: PricingItemType) => void
    value: number
  }
  specialProps: PricingItemType
}

const PricingOption = ({
  label,
  status = "none",
  iconProps,
  checkboxProps,
  specialProps,
}: PricingOptionProps) => {
  // console.log(checkboxProps)

  const WhatIcon = () => {
    const statusIconProps = {
      ...iconProps,
      className: `text-gray-400 ${iconProps?.className}`,
    }
    if (status === "available") return <MdCheckCircle {...statusIconProps} />
    else if (status === "unavailable") return <MdCancel {...statusIconProps} />
    else if (status === "checkable")
      return (
        <Checkbox
          {...checkboxProps}
          checked={checkboxProps?.checked}
          onChange={(e) => {
            // console.log(e)
            checkboxProps?.onChange(specialProps)
          }}
          size="small"
          sx={{
            /* bgcolor: "red", */
            p: 0,
            color: "#A8D1DC",
            "&.Mui-checked": {
              color: "#A8D1DC",
            },
          }}
          inputProps={{ "aria-label": "controlled" }}
        />
      )
    return null
  }
  return (
    <div className="flex items-center">
      <WhatIcon />
      <p className="ml-1 text-[#173E48] text-xs ">{label}</p>
    </div>
  )
}

export default PricingOption

import { FETCH_PLANS_URL } from "constants/api/endpoints"
import useSWR from "swr"
import { PricingPlansResponseType } from "types/API"
import { axiosFetcher } from "./apiRequest"

export const usePricingPlans = () => {
  const { data, error } = useSWR(FETCH_PLANS_URL,
    axiosFetcher,
  )

  return {
    plans: data as PricingPlansResponseType[],
    isLoading: !error && !data,
    error: error,
  }
}

/* export const testAPI = async () => {
  try {
    const data = await fetch("http://10.10.10.149/plans/")
    console.log("fetch", data)
  } catch (error) {
    console.log("fetch", error)
  }
}
 */

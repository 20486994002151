import React, { ComponentPropsWithRef, useCallback, useEffect } from "react"
import { EnterpriseFormFieldNameEnum } from "../../../types/EnterpriseForm"
import MuiButton from "../muiButton/MuiButton"
import { DropzoneOptions, useDropzone } from "react-dropzone"
import { useFormContext } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"

export type DragAndDropProps = {
  inputProps: ComponentPropsWithRef<"input"> & Required<Pick<ComponentPropsWithRef<"input">, "name">>
  dropzoneOptions?: DropzoneOptions,
  placeholders: { whyText: string, btnText: string }
  errorMessage: string
}

const DragAndDrop = ({
                       inputProps,
                       placeholders,
                       dropzoneOptions = {
                         maxFiles: 1,
                         multiple: false,
                         maxSize: 5 * 1000000,
                         accept: {
                           "image/*": [".png", ".jpeg", ".jpg"],
                         },
                       },
                       errorMessage,
                     }: DragAndDropProps) => {
  // {[key: string]: File[]}
  const { register, unregister, setValue, formState: { errors } } = useFormContext()

  const onDrop = useCallback((droppedFiles: File[]) => {
    setValue(inputProps.name, droppedFiles[0], { shouldValidate: true, shouldDirty: true })
  }, [setValue, inputProps.name])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...dropzoneOptions,
  })

  useEffect(() => {
    register(inputProps.name, {
      required: errorMessage,
    })
    return () => unregister(inputProps.name)
  }, [register, unregister, inputProps.name])

  return (
    <>
      <div
        {...getRootProps({ className: "dropzone" })}
        className="cursor-pointer mb-7 mt-4 py-6 px-5 bg-[#F7F9FA] rounded-md border-2 border-[#7BC3D5] border-dashed">
        <div className="flex items-center justify-between">
          <input
            type="file"
            {...getInputProps()}
          />
          <div>
            <p className="text--S5">
              {placeholders.whyText}
              &nbsp;
              <span className="font-semibold">({(dropzoneOptions?.maxSize as number) * 0.000001} Mb max.)</span>
            </p>
          </div>
          <MuiButton
            variant="outlined"
            className="font-medium px-4 text-black border-[#7BC3D5]">
            {placeholders.btnText}
          </MuiButton>
        </div>
        <div className="mt-9 flex items-center">
          {
            acceptedFiles &&
            acceptedFiles.map(files => <p key={files.name} className="text-[#399AB3] text-sous-menu ">{files.name}</p>)
          }
        </div>
      </div>
      <ErrorMessage
        errors={errors}
        name={inputProps.name}
        as="p"
        className="text--error"
      />
    </>
  )
}

export default DragAndDrop

export enum LegalPersonFormFieldNameEnum {
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  BIRTHDAY = "birthday",
  NATIONALITY = "nationality",
  IDENTITY_DOCUMENT = "identity_document",
}

export type LegalPersonFormType = {
  last_name: string | null
  first_name: string | null
  birthday: string | null
  nationality: string | null
  identity_document: File | null
}

export const LegalPersonFormDefaultValues: LegalPersonFormType = {
  last_name: "",
  first_name: "",
  birthday: "",
  nationality: "",
  identity_document: null,
}

import { PricingProps } from "components/common/pricing/Pricing"

export type PricingTitle = "Starter" | "Standard" | "Personnalisé"

export type PricingItemType = {
  id: string
  label: string
  status: "available" | "checkable" | "unavailable" | "none"
  value: number
  requested?: boolean
}

export enum PricingFormFieldNameEnum {
  PLAN = "plan",
  TOTAL = "total",
}

export type PricingFormType = {
  plan: string | null
  total?: number
  chosen_options: string[]
}

export type PricingOptionsDummyDataType = Omit<
  PricingProps,
  "pricingOptions" | "value" | "items" | "handlePricingChoice"
>

export const PricingFormDefaultValues: PricingFormType = {
  plan: "",
  total: 0,
  chosen_options: [],
}

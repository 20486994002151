import MuiButton from "components/common/muiButton/MuiButton"
import StepForm from "components/common/stepForm/StepForm"
import ViewLayout from "layout/viewLayout/ViewLayout"
import { MdKeyboardArrowLeft } from "react-icons/md"
import { Link } from "react-router-dom"

type AddClientProps = {}

const AddClient = (_props: AddClientProps) => {
  return (
    <ViewLayout>
      <div className="mx-4 lg:mx-0 mb-2">
        <Link to="/">
          <MuiButton
            startIcon={<MdKeyboardArrowLeft />}
            variant="text"
            className="px-0 up-text-blue text-xs lg:text-base leading[0px] ">
            Retourner à l'accueil
          </MuiButton>
        </Link>
      </div>
      <StepForm />
    </ViewLayout>
  )
}

export default AddClient

import { styled } from "@mui/material/styles"
import Stepper, { StepperProps } from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector"
import { StepIconProps } from "@mui/material/StepIcon"

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 16.5,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#399AB3",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#399AB3",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: "#173E48",
    borderRadius: 1,
  },
}))

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean }
}>(({ theme, ownerState }) => ({
  backgroundColor: "#fff",
  zIndex: 1,
  color: "#173E48",
  fontWeight: 700,
  width: 35,
  height: 35,
  display: "flex",
  border: "1px solid #173E48",
  borderRadius: "0.25rem",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    border: "1px solid #399AB3",
    color: "#fff",
    backgroundColor: "#399AB3",
  }),
  ...(ownerState.completed && {
    border: "1px solid #399AB3",
    color: "#fff",
    backgroundColor: "#399AB3",
  }),
}))

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}>
      {String(props.icon)}
    </ColorlibStepIconRoot>
  )
}

export type MuiStepProps = {
  steps: string[]
  stepperProps?: StepperProps
}

const MuiStep = ({ steps, stepperProps }: MuiStepProps) => {
  return (
    <Stepper
      {...stepperProps}
      alternativeLabel
      connector={<ColorlibConnector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default MuiStep

import clsx from "clsx"
import { IconType } from "react-icons"

export type LabelWithIconProps = {
  label: string
  Icon: IconType
  style?: {
    mainStyle?: string
    labelStyle?: string
    iconStyle?: string
  }
}

const LabelWithIcon = ({ label, Icon, style }: LabelWithIconProps) => {
  return (
    <div
      className={clsx("up-link-inactive flex items-center", style?.mainStyle)}>
      <Icon size="24px" />
      <p
        className={clsx(
          "ml-2 text-lg font-normal leading-[0px]",
          style?.labelStyle
        )}>
        {label}
      </p>
    </div>
  )
}

export default LabelWithIcon

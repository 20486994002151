import { Button, ButtonProps } from "@mui/material"

export type MuiButtonProps = ButtonProps

const MuiButton = (props: MuiButtonProps) => {
  return (
    <Button
      {...props}
      disableElevation
      disableRipple
      className={`text-base py-2 px-5 ${props.className}`}
      style={{ textTransform: "none" }}
    />
  )
}

export default MuiButton

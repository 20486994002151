export enum EnterpriseFormFieldNameEnum {
  IFU = "ifu",
  PROFESSIONAL_RISK = "professional_risk",
  COMPANY_NAME = "name",
  ADDRESS = "address",
  COLLECTIVE_AGREEMENT = "collective_agreement",
  LEGAL_FORM = "legal_form",
  CONTACT = "contact",
  EMAIL = "email",
  ADMIN_EMAIL = "admin_email",
  LOGO = "logo",
}

export type EnterpriseFormType = {
  ifu: string | null
  professional_risk: string | null
  name: string | null
  address: string | null
  legal_form: string | null
  collective_agreement: string | null
  email: string | null
  admin_email: string | null
  contact: string | null
  logo: File | string
}

export const EnterpriseFormDefaultValues: EnterpriseFormType = {
  ifu: "",
  professional_risk: "",
  name: "",
  address: "",
  legal_form: "",
  collective_agreement: "",
  email: "",
  admin_email: "",
  contact: "",
  logo: "",
}

import { BrowserRouter as Router } from "react-router-dom"
import Header from "layout/main/header/Header"
import AppRoutes from "routes/appRoutes"
import Sidebar from "layout/main/sidebar/Sidebar"

const App = () => {
  return (
    <div className="">
      <Router>
        {/*<ProtectedRoute>*/}
        <Sidebar />
        <div className="lg:ml-[20%]">
          <Header />
          <AppRoutes />
        </div>
        {/*</ProtectedRoute>*/}
      </Router>
    </div>
  )
}

export default App

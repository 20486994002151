import React from "react"

type ViewLayoutProps = {
  children: React.ReactNode
}

const ViewLayout = ({ children }: ViewLayoutProps) => {
  return <div className="container up-bg-gray lg:px-9 !pt-20">{children}</div>
}
/* const ViewLayout = ({ children }: ViewLayoutProps) => {
  return (
    <div className="w-full up-bg-gray px-7 lg:px-9 py-10 lg:py-12 !pt-20">
      {children}
    </div>
  )
} */

export default ViewLayout

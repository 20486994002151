import ViewLayout from "layout/viewLayout/ViewLayout"

export type ClientProps = {}

const Client = (props: ClientProps) => {
  return (
    <ViewLayout>
      <h1>Client</h1>
    </ViewLayout>
  )
}

export default Client

import { Link } from "react-router-dom"
import {
  MdNotifications,
  MdOutlineKeyboardArrowDown,
  MdOutlineMenu,
} from "react-icons/md"
import { useContext, useEffect, useState } from "react"
import {
  SidebarResponsiveContext,
  SidebarResponsiveContextValueType,
} from "context/SidebarResponsiveContext"
import { useKeycloak } from "@react-keycloak/web"

const Header = () => {
  const [userInfo, setuserInfo] = useState<{} | any>({})

  const { isExpanded, expand } = useContext(
    SidebarResponsiveContext
  ) as SidebarResponsiveContextValueType

  const { keycloak } = useKeycloak()

  useEffect(() => {
    keycloak
      .loadUserInfo()
      .then((user) => {
        setuserInfo(user)
        // console.log(user)
      })
      .catch((error) => console.log(error))
  }, [])

  {
    /* <div className={`fixed bg-red-300 col-span-8`}> */
  }
  return (
    <div className="fixed z-20 w-full lg:w-[80%] bg-white py-4 px-9 flex justify-between items-center shadow-sm">
      <MdOutlineMenu
        size="24px"
        color="#399AB3"
        onClick={expand}
        className="lg:hidden"
      />
      <Link to="/" className="no-underline">
        <h1 className="font-medium text-xl lg:hidden leading-[0px] up-text-blue">
          Uptimise | Gestionnaire
        </h1>
      </Link>
      <div className="flex items-center">
        <MdNotifications size="24px" className="up-text-blue" />
        <div className="w-8 h-8 rounded-full flex items-center justify-center ml-4">
          <img
            alt="user avatar"
            src="https://randomuser.me/api/portraits/med/men/75.jpg"
            width="30px"
            className="object-contain rounded-full"
          />
        </div>
        <p className="text-lg leading-[0px] ml-4 up-text-blue hidden lg:block">
          {userInfo?.preferred_username}
        </p>
        <MdOutlineKeyboardArrowDown size="24px" className="ml-4 up-text-blue" />
      </div>
    </div>
  )
  /* </div> */
}

/* const Header = () => {
  const { isExpanded, expand } = useContext(
    SidebarResponsiveContext
  ) as SidebarResponsiveContextValueType

  return (
    <div
      className={`fixed bg-white z-50 w-full h-[${HEADER_HEIGTH}px]   md:w-[calc(100%-${DRAWER_WIDTH}px)]`}>
      <div className="py-4 px-9 flex justify-between items-center shadow-sm">
        <MdOutlineMenu
          size="24px"
          color="#399AB3"
          onClick={expand}
          className="sm:hidden"
        />
        <Link to="/" className="no-underline">
          <h1 className="font-medium text-xl lg:hidden leading-[0px] up-text-blue">
            Uptimise | Gestionnaire
          </h1>
        </Link>
        <div className="flex items-center">
          <MdNotifications size="24px" className="up-text-blue" />
          <div className="w-8 h-8 rounded-full flex items-center justify-center ml-4">
            <img
              alt="user avatar"
              src="https://randomuser.me/api/portraits/med/men/75.jpg"
              width="30px"
              
              className="object-contain rounded-full"
            />
          </div>
          <p className="text-lg leading-[0px] ml-4 up-text-blue hidden lg:block">
            Jean-Eude
          </p>
          <MdOutlineKeyboardArrowDown
            size="24px"
            className="ml-4 up-text-blue"
          />
        </div>
      </div>
    </div>
  )
}
 */
export default Header

import ViewLayout from "layout/viewLayout/ViewLayout"

export type InvoicesProps = {}

const Invoices = (props: InvoicesProps) => {
  return (
    <ViewLayout>
      <div>
        <h1>Invoices</h1>
      </div>
    </ViewLayout>
  )
}

export default Invoices

import React from "react"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert, { AlertProps } from "@mui/material/Alert"

export type ActionNotificationProps = {
  open?: boolean
  severity?: AlertProps["color"]
  message?: string
  handleClose: (event?: React.SyntheticEvent | Event, reason?: string) => void
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const ActionNotification = ({
  open = false,
  message = "",
  severity = "success",
  handleClose,
}: ActionNotificationProps) => {
  let messageHasAsterisk = message?.indexOf("*") != -1
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {
          messageHasAsterisk ? message?.split("*").map(str => <><p>{str}</p></>) : message
        }
      </Alert>
    </Snackbar>
  )
}

export default ActionNotification

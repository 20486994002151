import { Chip, ChipProps } from "@mui/material"

export type DataGridChipProps = ChipProps & {
  type: "PENDING" | "VALIDATED" | "SUSPENDED" | "TRYING"
}

export const ChipState: Array<{ value: DataGridChipProps["type"], style: string, label: string }> = [
  {
    value: "PENDING",
    style: "bg-[#FDEDD9] text-[#F4921B]",
    label: "En attente",
  },
  {
    value: "VALIDATED",
    style: "bg-[#D4F7E8] text-[#21A871]",
    label: "Validée",
  },
  {
    value: "SUSPENDED",
    style:
      "bg-[#FDDDE2] text-[#F13153]",
    label: "Suspendu",
  },
  {
    value: "TRYING",
    style:
      "bg-[#E8F4F8] text-[#399AB3]",
    label: "Essai",
  },
]

const DataGridChip = (props: DataGridChipProps) => {
  return (
    <Chip
      {...props}
      className={`px-2 lg:px-4 py-1 lg:text-sm text-xs leading-[0px] font-semibold ${
        props.className
      } ${ChipState.find(v => v.value === props.type).style ?? ""}`}
    />
  )
}

export default DataGridChip

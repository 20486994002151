import ViewLayout from "layout/viewLayout/ViewLayout"

export type OfferProps = {}

const Offer = (props: OfferProps) => {
  return (
    <ViewLayout>
      <div>
        <h1>Offer</h1>
      </div>
    </ViewLayout>
  )
}

export default Offer

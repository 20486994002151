import {
  Card,
  CardActions,
  CardActionsProps,
  CardContent,
  CardContentProps,
  CardProps,
} from "@mui/material"
import { hasRequestedAsProp } from "helpers/typeChecking"
import { memo, useReducer } from "react"
import { MdDone } from "react-icons/md"
import {
  PricingOptionActionValues,
  reducerPricingOption,
} from "reducer/reducerPricingOption"
import { HPropsType, PPropsType } from "types/HTMLComponentsProps"
import { PricingItemType, PricingTitle } from "types/PricingForm"
import MuiButton, { MuiButtonProps } from "../muiButton/MuiButton"
import PricingOption from "../pricingOption/PricingOption"

export type PricingProps = {
  isSelected?: boolean
  id: string
  title: PricingTitle
  titleProps?: HPropsType
  subTitle: string
  subTitleProps?: PPropsType
  description: string
  descriptionProps?: PPropsType
  cardProps?: CardProps
  cardContentProps?: CardContentProps
  cardActionsProps?: CardActionsProps
  btnProps?: MuiButtonProps
  handlePricingChoice: (
    pricingTitle: PricingTitle,
    value: string,
    pricingOptions: PricingItemType[]
  ) => void
  items: PricingItemType[]
}

const Pricing = ({
  isSelected = false,
  id,
  items,
  handlePricingChoice,
  title,
  titleProps,
  subTitle,
  subTitleProps,
  description,
  descriptionProps,
  cardProps,
  cardContentProps,
  cardActionsProps,
  btnProps,
}: PricingProps) => {
  /**
   * This reducer is used to update pricing options checked by the user
   * pricingOptions: list of options
   * dispatchPricingOptions: function to update options
   */
  const [pricingOptions, dispatchPricingOptions] = useReducer(
    reducerPricingOption,
    items
  )

  const handlePricingToggle = (pricingOption: PricingItemType) => {
    if (typeof dispatchPricingOptions == "function") {
      dispatchPricingOptions({
        type: PricingOptionActionValues.TOGGLE,
        payload: {
          pricingID: id,
          id: pricingOption.id,
          value: pricingOption.value ?? 0,
        },
      })
    }
  }

  return (
    <Card
      variant="outlined"
      {...cardProps}
      className={`mt-4 flex flex-col justify-between h-full pt-2 pb-5 px-2 w-[270px] rounded-xl border-[#E8F4F8] shadow-sm text-[#173E48] hover:border-[#2E7B8F] hover:shadow-lg ${cardProps?.className}`}>
      <CardContent {...cardContentProps}>
        <h1
          {...titleProps}
          className={`text-2xl font-semibold mb-6 hover:text-[#2E7B8F] ${
            titleProps?.className
          } ${isSelected ? "!text-[#2E7B8F]" : ""}`}>
          {title}
        </h1>
        <p
          {...subTitleProps}
          className={`text-[#173E48] text-xs ${subTitleProps?.className}`}>
          {subTitle}
        </p>
        <p
          {...descriptionProps}
          className={`text-gray-400 text-xs mb-6 ${descriptionProps?.className}`}>
          {description}
        </p>
        <div className="flex flex-col">
          {pricingOptions.map((eachItems, index) => {
            return hasRequestedAsProp(eachItems) ? (
              <PricingOption
                key={index}
                {...eachItems}
                specialProps={eachItems}
                checkboxProps={{
                  checked: eachItems?.requested as boolean,
                  value: eachItems.value as number,
                  onChange: handlePricingToggle,
                }}
              />
            ) : (
              <PricingOption
                key={index}
                specialProps={eachItems}
                {...eachItems}
              />
            )
          })}
        </div>
      </CardContent>
      <CardActions /* className="bg-red-300" */ {...cardActionsProps}>
        <MuiButton
          {...btnProps}
          onClick={() => handlePricingChoice(title, id, pricingOptions)}
          startIcon={isSelected ? <MdDone /> : null}
          variant="contained"
          className={`bg-[#173E48] hover:bg-[#399AB3]  m-auto text-sm px-10 ${
            btnProps?.className
          } ${isSelected ? "!bg-[#399AB3]" : ""}`}>
          {isSelected ? "Offre choisie" : "Choisir cette offre"}
        </MuiButton>
      </CardActions>
    </Card>
  )
}

export default memo(Pricing)

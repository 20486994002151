import { InputUnstyledOwnProps } from "@mui/base"
import InputUnstyled, { inputUnstyledClasses } from "@mui/base/InputUnstyled"
import { styled } from "@mui/system"
import InputMask, { Props } from "react-input-mask"

const blue = {
  100: "#DAECFF",
  200: "#80BFFF",
  400: "#3399FF",
  600: "#0072E5",
}

const grey = {
  50: "#F9F9F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CCE4EA",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
}

const Input = styled(InputUnstyled)(
  ({ theme }) => `
  
  .${inputUnstyledClasses.input} {
    width: 100%;
		height: 40px;
    font-size: 0.8rem;
		font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color:  ${grey[900]};
    background: ${grey[50]};
    border: 1px solid ${grey[300]};
    border-radius: 0.25rem;
    padding: 6px 12px;

    &:hover {
      background: ${grey[100]};
      border-color: ${grey[400]};
    }

    &:focus {
      outline: 3px solid ${blue[100]};
    }
  }
`,
)

export type MuiInputProps = {
  label?: string
  labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement>
  inputProps?: InputUnstyledOwnProps
  inputMask?: Props
}

const MuiInput = ({ label, labelProps, inputProps, inputMask }: MuiInputProps) => {
  return (
    <>
      {label && (
        <label {...labelProps} className="font-medium">
          {label}
        </label>
      )}
      {inputMask ?
        <InputMask
        maskChar="_"
        {...inputMask}
      >
        {(props) => <Input {...props} className={`mt-2 mb-4 ${inputProps?.className}`} />}
      </InputMask>
      :
        <Input {...inputProps} className={`mt-2 mb-4 ${inputProps?.className}`} />
      }
    </>
  )
}

export default MuiInput

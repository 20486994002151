import ViewLayout from "layout/viewLayout/ViewLayout"

export type ClosedAssignmentProps = {}

const ClosedAssignment = (props: ClosedAssignmentProps) => {
  return (
    <ViewLayout>
      <div>
        <h1>Closed Assignment</h1>
      </div>
    </ViewLayout>
  )
}

export default ClosedAssignment

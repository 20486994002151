import useToggle from "hooks/useToggle"
import { createContext } from "react"

export type SidebarResponsiveContextValueType = {
  isExpanded: boolean
  expand: (nextValue?: any) => void
}

export const SidebarResponsiveContext = createContext<
  SidebarResponsiveContextValueType | undefined
>(undefined)

const SidebarResponsiveContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isExpanded, expand] = useToggle(false)
  return (
    <SidebarResponsiveContext.Provider value={{ isExpanded, expand }}>
      {children}
    </SidebarResponsiveContext.Provider>
  )
}

export default SidebarResponsiveContextProvider

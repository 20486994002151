import React, { forwardRef } from "react"
import "./style.css"
import fr from "react-phone-input-2/lang/fr.json"
import "react-phone-input-2/lib/high-res.css"
import PI from "react-phone-input-2"
import PropTypes from "prop-types"
import { PhoneNumberUtil } from "google-libphonenumber"

const phoneUtil = PhoneNumberUtil.getInstance();

export const handleValidate = (value, countryCode) => {
  const number = phoneUtil.parse(value, countryCode);
  return phoneUtil.isValidNumber(number)
};

const PhoneInput = (
  { defaultCountry = "bj", value, onChange, isCodeEditable = false },
  ref
) => {
  const ReactPhoneInput = PI.default ? PI.default : PI;

  return (
    <ReactPhoneInput
      country={defaultCountry}
      localization={fr}
      autocompleteSearch
      value={value}
      onChange={onChange}
      masks={{ bj: ".. .. .. .." }}
      containerClass="custom-phone-wrapper"
      inputClass="custom-phone-input"
      buttonClass="custom-phone-btn"
      buttonStyle={{ borderRadius: "4px 0 0 4px" }}
      searchStyle={{ margin: "0", width: "97%", height: "30px" }}
      countryCodeEditable={false}
      enableSearch
      disableSearchIcon
    />
  );
};

export default forwardRef(PhoneInput);

PhoneInput.propTypes = {
  defaultCountry: PropTypes.string,
  isCodeEditable: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

import React from "react"
import ReactDOM from "react-dom/client"
import "./styles/global.css"
import reportWebVitals from "./reportWebVitals"
import { CircularProgress, StyledEngineProvider } from "@mui/material"
import SidebarResponsiveContextProvider from "context/SidebarResponsiveContext"
import App from "App"
import { ReactKeycloakProvider } from "@react-keycloak/web"
import ActionNotificationContextProvider from "context/ActionNotificationContext"
import keycloak from "helpers/keycloakUtils"
import { ENVIRONMENT, SENTRY_DSN } from "./constants/settings"
import * as Sentry from "@sentry/react"
import { BrowserTracing, Replay } from "@sentry/react";

if (ENVIRONMENT)
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    integrations: [
      new BrowserTracing(),
      new Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: "login-required" }}
                         LoadingComponent={
                           <div className="flex items-center justify-center h-screen">
                             <CircularProgress />
                           </div>}>
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ActionNotificationContextProvider>
          <SidebarResponsiveContextProvider>
            <App />
          </SidebarResponsiveContextProvider>
        </ActionNotificationContextProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  </ReactKeycloakProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import {
  MdOutlineBarChart,
  MdOutlineDoneAll,
  MdOutlineGridView,
  MdOutlineLogout,
  MdOutlinePointOfSale,
  MdOutlineSettings,
  MdOutlineSwitchAccount,
  MdOutlineTask,
} from "react-icons/md"
import { NavigationItemType } from "types/NavigationItem"

export const NavigationItemList: NavigationItemType[] = [
  {
    label: "Tableau de board",
    link: "/",
    Icon: MdOutlineGridView,
  },
  {
    label: "Clients",
    link: "/clients",
    Icon: MdOutlineSwitchAccount,
  },
  {
    label: "Offres",
    link: "/offres",
    Icon: MdOutlineTask,
  },
  {
    label: "Factures",
    link: "/factures",
    Icon: MdOutlineSwitchAccount,
  },

  {
    label: "Activités",
    link: "/activites",
    Icon: MdOutlinePointOfSale,
  },

  {
    label: "Affectations Closes",
    link: "/affectations-closes",
    Icon: MdOutlineDoneAll,
  },

  {
    label: "Rapports",
    link: "/rapports-statistiques",
    Icon: MdOutlineBarChart,
  },

  {
    label: "Paramètres",
    link: "/parametres",
    Icon: MdOutlineSettings,
  } /* 
  {
    label: "Se déconnecter",
    link: "/logout",
    Icon: MdOutlineLogout,
  }, */,
]

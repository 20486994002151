import { AxiosError, AxiosRequestConfig } from "axios"
import { http } from "../utils/http"

export const postRequest = <T>(url: string, toPost: T) =>
  http.post(url, toPost, {
    headers: {
      "Content-Type": "application/json",
      // "client": "public"
    },
  })
    .then((res) => res.data)

export const axiosFetcher = (url: string) =>
  http.get(url).then((res) => res.data)

export const postDataWithFeedback = async (
  url: string,
  formData: any,
  config?: AxiosRequestConfig<any>,
) => {
  try {
    let res = await http.post(url, formData, config)
    return {
      data: res.data,
    }
  } catch (error) {
    return {
      error,
    }
  }
}

export const handleAPIErrors = (error: AxiosError) => {
  if (error.code === "ERR_NETWORK") {
    return "Erreur du réseau. Vérifiez que vous êtes bien connectés à internet."
  } else if (error.request) {
    return error.request.responseText
  }
  return ""
}

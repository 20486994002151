import axios from "axios"
import keycloak from "../helpers/keycloakUtils"


const http = axios.create({
  headers: { "Content-Type": "application/json" },
})

// Request interceptor
http.interceptors.request.use(
  (config) => {
    // config.headers!.App = "uptimise-app";
    config.headers!.Authorization = `Bearer ${keycloak.token}`
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Response interceptor
http.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true
      await keycloak.updateToken(1800)
      axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`
      return http(originalRequest)
    }
    return Promise.reject(error.response)
  },
)

export { http }

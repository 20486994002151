import MuiButton from "components/common/muiButton/MuiButton"
import CardWithStatistic from "components/common/cardWithStatisitic/CardWithStatistic"
import { momentFr } from "helpers/momentLang"
import ViewLayout from "layout/viewLayout/ViewLayout"
import { MdAdd } from "react-icons/md"
import { Link, useLocation } from "react-router-dom"
import ClientWithDataGrid from "components/clientWithDataGrid/ClientWithDataGrid"
import { useContext, useEffect } from "react"
import { ActionNotificationContext, ActionNotificationContextValeurType } from "../../context/ActionNotificationContext"

export type DashboardProps = {}

const Dashboard = (props: DashboardProps) => {
  const { displayAlert } = useContext(
    ActionNotificationContext,
  ) as ActionNotificationContextValeurType

  const {state} = useLocation();

  useEffect(() => {
    if (state) {
      displayAlert(
        (state as {message: string}).message,
        "success",
      )
    }
  }, [state])

  return (
    <ViewLayout>
      <div className="px-4 lg:px-0">
        <p className="up-text-blue text-xs leading[0px] mb-2">
          {momentFr.format("ll")}
        </p>
        <p className="up-text-blue text-xl leading[0px] font-medium mb-10">
          Bonjour Mano
        </p>
        <p className="up-text-blue text-xl leading[0px] font-bold">
          Tableau de board
        </p>
        <div className="w-[100%] grid grid-cols-2 lg:grid-cols-4 gap-5 mb-10 lg:mb-12">
          <CardWithStatistic total={200} title="Contrat valide" quantity={12} />
          <CardWithStatistic
            total={400}
            title="Contrat suspendu"
            quantity={10}
          />
          <CardWithStatistic
            total={600}
            title="Attente de paiement"
            quantity={110}
          />
          <CardWithStatistic
            total={1000}
            title="Contrat valide"
            quantity={105}
          />
        </div>
        <div className="flex justify-end mb-3 lg:mb-7">
          <Link to="ajout-client">
            <MuiButton
              className="bg-[#399AB3]"
              variant="contained"
              startIcon={<MdAdd />}>
              Ajouter un client
            </MuiButton>
          </Link>
          {/* <Outlet /> */}
        </div>

        <ClientWithDataGrid />
      </div>
    </ViewLayout>
  )
}

export default Dashboard

import { Outlet } from "react-router-dom"

const Home = () => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default Home

import Box from "@mui/material/Box"
import MuiStep from "components/common/muiStep/MuiStep"
import MuiButton from "components/common/muiButton/MuiButton"
import { useContext, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { AllDefaultValues, AllStepFormType, STEPS } from "types/StepForm"
import StepComponents from "./StepComponents"
import { transformDataBasedOnActiveStep } from "helpers/stepForm"
import { PricingFormType } from "types/PricingForm"
import { AxiosRequestConfig } from "axios"
import {
  GET_ADD_REPRESENTATIVES_URL,
  GET_ADD_SUBSCRIPTION_URL,
  GET_CREATE_COMPANIES_URL,
} from "constants/api/endpoints"

import { handleAPIErrors, postDataWithFeedback } from "api/apiRequest"
import { ActionNotificationContext, ActionNotificationContextValeurType } from "context/ActionNotificationContext"
import { http } from "../../../utils/http"

export type StepFormProps = {}

const StepForm = (props: StepFormProps) => {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const companyID = useRef("")

  const { displayAlert } = useContext(
    ActionNotificationContext,
  ) as ActionNotificationContextValeurType

  const handleFormData = async (formData: AllStepFormType | null) => {
    const dataToPost = transformDataBasedOnActiveStep(
      activeStep,
      formData as AllStepFormType,
    )

    const url = formAPIUrl(activeStep)

    setLoading(true)
    const { data, error } = await postDataWithFeedback(url, dataToPost)
    setLoading(false)

    if (error) {
      failedResponseHandling(error)
    } else {
      successResponseHandling(data)

      handleNext()
    }
    // console.log(companyID.current)
  }

  // TODO:
  // * Les erreurs de validation API via setErrors
  // * A la dernière étape du Step, renvoyer à l'accueil avec un message: "Enregistrement du client en cours"
  // *

  const handleNext = () => {
    if (activeStep == 2) navigate("/", {
      state: { message: "L'enregistrement du client est en cours" },
    })
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
    reset({ ...AllDefaultValues[0] })
    reset({ ...AllDefaultValues[1] })
    reset({ ...AllDefaultValues[2] })
  }

  const handleCancel = () => {
    handleReset()
    navigate("/", { replace: true })
  }

  const methods = useForm<AllStepFormType>({
    defaultValues: AllDefaultValues[activeStep],
    mode: "all",
  })

  const { handleSubmit, reset, ...formActions } = methods

  /* API */
  const formAPIUrl = (activeStep: number) => {
    switch (activeStep) {
      case 0: {
        return GET_CREATE_COMPANIES_URL
      }
      case 1: {
        return GET_ADD_REPRESENTATIVES_URL.replace(":id", companyID.current)
      }

      default: {
        return GET_ADD_SUBSCRIPTION_URL.replace(":id", companyID.current)
      }
    }
  }

  const postData = (
    data: PricingFormType | FormData,
    url: string,
    config?: AxiosRequestConfig<any>,
  ) => {
    http
      .post(url, data, config)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => console.log("end request"))
  }

  const handleValidationErrors = (errorList: { [key: string]: string[] }) => {
    for (const [key, value] of Object.entries(errorList)) {
      // @ts-ignore
      formActions.setError(key, { type: "manual", message: value.join(". ") })
    }
  }

  const failedResponseHandling = (error?: any) => {
    // console.log(error)

    if (error.code === "BAD_REQUEST" && error.response && error.response.data) {
      const errorList = error.response.data as { [key: string]: string[] }
      handleValidationErrors(errorList)
    }

    if (activeStep === 0) {
      displayAlert(
        "Une erreur est survenue lors de l'envoi des données de l'entreprise. " + handleAPIErrors(error),
        "error",
      )
    } else if (activeStep === 1) {
      displayAlert(
        "Une erreur est survenue lors de l'envoi des données du représentant légal." + handleAPIErrors(error),
        "error",
      )
    } else if (activeStep === 2) {
      displayAlert("Une erreur est survenue lors du choix des plans." + handleAPIErrors(error), "error")
    }

  }

  const successResponseHandling = (data?: any) => {
    if (activeStep === 0) {
      companyID.current = data.id
      displayAlert(
        "Les données de l'entreprise sont bien enregistrées.",
        "success",
      )
    } else if (activeStep === 1) {
      displayAlert(
        "Les données du représentant légal sont bien enregistrées.",
        "success",
      )
    } else if (activeStep === 2) {
      displayAlert("Les données du plan sont bien enregistrées.", "success")
    }
  }

  return (
    <Box sx={{ width: "100%" /* height: "100vh" */ }}>
      <div className="grid grid-cols-12 ">
        <div className="col-span-10 col-start-2 lg:col-span-8 lg:col-start-3">
          <MuiStep steps={STEPS} stepperProps={{ activeStep: activeStep }} />
        </div>
      </div>
      {/*  <div className="bg-white mx-auto lg:mx-52 pb-3 px-5 "> */}
      {/* <div className="px-28"> */}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleFormData)} className="">
          {activeStep === STEPS.length ? (
            <div className="pt-4">
              <h2>Vous avez terminé l'enregistrement.</h2>
              <p className="text-lg">
                Cliquez
                <Link to="/">
                  <span className="text-blue-300 mx-1">ici</span>
                </Link>
                pour revenir à l'accueil.
              </p>
            </div>
          ) : (
            <div className="">
              <p className="font-semibold text-sm lg:text-lg pt-3 pl-7 mt-14 lg:mt-[61px]">
                {STEPS[activeStep]}
              </p>
              <StepComponents activeStep={activeStep} {...formActions} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  pt: 2,
                  pb: 2,
                  mr: "10%",
                }}>
                <MuiButton
                  variant="outlined"
                  className="border-[#399AB3]"
                  color="inherit"
                  /* disabled={activeStep === 0} */
                  onClick={activeStep === 0 ? handleCancel : handleBack}
                  sx={{ mr: 1 }}>
                  {activeStep === 0 ? "Annuler" : "Précédent"}
                </MuiButton>

                <MuiButton
                  /*  onClick={
                      activeStep === STEPS.length - 1 ? handleNext : handleNext
                    } */
                  /*loading={isLoading}*/
                  type="submit"
                  variant="contained"
                  className="bg-[#399AB3]">
                  {activeStep === STEPS.length - 1 ? "Terminé" : "Enregistrer"}
                </MuiButton>
              </Box>
            </div>
          )}
        </form>
      </FormProvider>
      {/* </div> */}
    </Box>
  )
}

export default StepForm

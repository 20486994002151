import { PricingFormDefaultValues } from "./../types/PricingForm"
import {
  PricingOptionsType,
  PricingPlansAfterFormatType,
  PricingPlansResponseType,
} from "types/API"
import { EnterpriseFormDefaultValues } from "types/EnterpriseForm"
import { LegalPersonFormDefaultValues } from "types/LegalPersonForm"
import { PricingItemType, PricingTitle } from "types/PricingForm"
import { AllStepFormType } from "types/StepForm"

/**
 *
 * @param item array of pricing options where users checked some of them
 * @returns number: total cost from the checked pricing options
 */
export const totalFromPricingOptions = (item: PricingItemType[]) => {
  return item
    .filter((pricingOption) => pricingOption.requested === true)
    .reduce((prev, curr) => prev + (curr.value ?? 0), 0)
}

export const choosedPricingOptions = (item: PricingItemType[]) => {
  return item
    .filter((pricingOption) => pricingOption.requested === true)
    .map((pricingOption) => pricingOption.id)
}

export const orderOptionByStatus = (item: PricingItemType[]) => {
  const available = []
  const unavailable = []
  const checkable = []
  for (const option of item) {
    if (option.status === "available") {
      available.push(option)
    } else if (option.status === "checkable") {
      checkable.push(option)
    } else {
      unavailable.push(option)
    }
  }

  return available.concat(checkable, unavailable)
}

export const transformDataIntoFormData = <T>(
  data: AllStepFormType,
  DefaultData: T
) => {
  let formData = new FormData()
  for (const [key, value] of Object.entries(data)) {
    // console.log(key, value)
    if (key in DefaultData) {
      formData.append(key, value)
    }
  }

  return formData
}

export const getPricingPlanData = (data: AllStepFormType) => {
  let pricingPlan = PricingFormDefaultValues
  if ("plan" in data) {
    pricingPlan = {
      plan: data.plan,
      chosen_options: data.chosen_options,
    }
  }

  return pricingPlan
}

export const transformDataBasedOnActiveStep = (
  activeStep: number,
  data: AllStepFormType
) => {
  switch (activeStep) {
    case 0: {
      return transformDataIntoFormData(data, EnterpriseFormDefaultValues)
    }

    case 1: {
      return transformDataIntoFormData(data, LegalPersonFormDefaultValues)
    }

    default: {
      return getPricingPlanData(data)
    }
  }
}

export const matchPlanTitleWithName = (
  name: PricingPlansResponseType["name"] | string
): PricingTitle | string => {
  switch (name) {
    case "STARTER": {
      return "Starter"
    }

    case "STANDARD": {
      return "Standard"
    }

    case "CUSTOM": {
      return "Personnalisé"
    }
    default:
      return name
  }
}

export const matchPlanOptionWithType = (
  type: PricingOptionsType["type"]
): PricingItemType["status"] => {
  switch (type) {
    case 1: {
      return "available"
    }

    case 2: {
      return "checkable"
    }

    case 3: {
      return "unavailable"
    }
  }
}

export const transformAPIDataToOptionsData = (
  data: PricingOptionsType
): PricingItemType => {
  return {
    id: data.option.id,
    label: data.option.label,
    value: data.option.amount,
    status: matchPlanOptionWithType(data.type),
    requested: data.type === 2 ? false : undefined,
  }
}

export const transformAPIDataToPricingPlangData = (
  data: PricingPlansResponseType[]
): PricingPlansAfterFormatType[] => {
  const plans: PricingPlansAfterFormatType[] = data.map((plan, index) => ({
    title: matchPlanTitleWithName(plan.name),
    id: plan.id,
    subTitle: "",
    description: plan.description,
    items: orderOptionByStatus(plan.options.map(transformAPIDataToOptionsData)),
  }))

  return plans
}

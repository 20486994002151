import "./ClientWithDataGrid.css"
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  gridPageSelector,
  GridRowParams,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid"
import { ClientDataGridDataType } from "types/ClientDataGrid"
import DataGridChip, { ChipState } from "components/dataGridChip/DataGridChip"
import { Pagination } from "@mui/material"
import { clientRegistrationState } from "helpers/dashboard"
import { deleteRequest, invitationRequest, useClientDataGrid } from "api/clientForDataGrid"
import { useContext, useEffect, useState } from "react"
import { matchPlanTitleWithName } from "../../helpers/stepForm"
import moment from "moment"
import { COMPANY_INVITATION_REQUEST_URL } from "../../constants/api/endpoints"
import { ActionNotificationContext, ActionNotificationContextValeurType } from "context/ActionNotificationContext"

export type ClientWithDataGridProps = {}

const ClientWithDataGrid = (_props: ClientWithDataGridProps) => {
  const { displayAlert } = useContext(
    ActionNotificationContext,
  ) as ActionNotificationContextValeurType

  const [pageIndex, setPageIndex] = useState(1)

  const handleInvitationRequest = (url: string) => {
    invitationRequest(url)
      .then(() => displayAlert("Invitation envoyée", "success"))
      .catch(() => displayAlert("L'invitation n'a pas pu être envoyée", "error"))
  }

  const columns: GridColumns<ClientDataGridDataType> = [
    {
      field: "clientID",
      headerName: "N° Client",
      headerClassName: "bg-[#E8F4F8] up-text-green-2",
      flex: 2,
      disableColumnMenu: true,
      renderCell: (_params) => (
        <>-</>
      ),
    },
    {
      field: "contractID",
      headerName: "N° Contrat",
      headerClassName: "bg-[#E8F4F8] up-text-green-2",
      flex: 3,
      disableColumnMenu: true,
      renderCell: (_params) => (
        <>-</>
      ),
    },
    {
      field: "client_name",
      headerName: "Nom du client",
      headerClassName: "bg-[#E8F4F8] up-text-green-2",
      flex: 4,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            <img
              className="userListImg"
              src={params.row.company.logo}
              alt={params.row.company.name + " logo"}
            />
            <p className="userListUsername word--wrap">{params.row.company.name}</p>
          </div>
        )
      },
    },
    {
      field: "legal_person",
      headerName: "Représentant",
      headerClassName: "bg-[#E8F4F8] up-text-green-2",
      flex: 4,
      disableColumnMenu: true,
      renderCell: (_params) => {
        return (
          <div className="userListUser">
            -
          </div>
        )
      },
    },
    {
      field: "offer",
      headerName: "Offre",
      headerClassName: "bg-[#E8F4F8] up-text-green-2",
      flex: 3,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>{matchPlanTitleWithName(params.row.plan.name)}</div>
      ),
    },
    {
      field: "start_date",
      headerName: "Date de début",
      headerClassName: "bg-[#E8F4F8] up-text-green-2",
      flex: 3,
      disableColumnMenu: true,
      renderCell: (params) => {

        return (
          <div>{moment(params.row.subscribe_at).format("L")}</div>
        )
      },
    },
    {
      field: "status",
      headerName: "Statut",
      headerClassName: "bg-[#E8F4F8] up-text-green-2",
      flex: 3,
      disableColumnMenu: true,
      renderCell: (params) => {
        const state = clientRegistrationState(params.row.is_deleted, params.row.is_active)
        return (
          <DataGridChip type={state} label={ChipState.find(v => v.value === state)?.label} />
        )
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "bg-[#E8F4F8] up-text-green-2",
      flex: 3,
      disableColumnMenu: true,
      type: "actions",
      getActions: (params: GridRowParams<ClientDataGridDataType>) => [
        /* <GridActionsCellItem label="Modifier" onClick={() => {
         }} showInMenu={true} />,*/
        //TODO: add something to indicate if it ressouce 404 or route 404
        <GridActionsCellItem label="Supprimer" onClick={() => deleteRequest("")} showInMenu={true} />,
        <GridActionsCellItem label="Inviter"
                             onClick={() => handleInvitationRequest(COMPANY_INVITATION_REQUEST_URL.replace(":id", params.row.company.id))}
                             showInMenu={true} />,
      ],
    },
  ]

  const CustomPagination = () => {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    // const pageCount = useGridSelector(apiRef, gridPageCountSelector)
    return (
      <Pagination
        count={pageCount}
        variant="outlined"
        shape="rounded"
        page={page + 1}
        onChange={(_event, value) => {
          setPageIndex(value)
          apiRef.current.setPage(value - 1)
        }}
      />
    )
  }

  // console.log(transformAPIDataToClientsData(FromAPIClientsDataGrid))
  const { isLoading, clients, error } = useClientDataGrid(pageIndex)
  const [rowCount, setRowCount] = useState<number>(clients?.count || 0)
  const [pageCount, setPageCount] = useState<number>(10)

  useEffect(() => {
    setRowCount((prevRowCount) =>
      clients?.count !== undefined ? clients?.count : prevRowCount,
    )
  }, [clients?.count, setRowCount])

  useEffect(() => {
    setPageCount(
      (prevPageCount) => clients?.total_pages !== undefined ? clients?.total_pages : prevPageCount,
    )
  }, [clients?.total_pages, setPageCount])

  // console.log(isLoading, clients, error)

  if (error) {
    return <div>Une erreur est survenue lors de la lecture des données...</div>
  }

  return (
    <div className="h-96">
      <DataGrid
        pagination
        rows={isLoading ? [] : clients.results ?? []}
        columns={columns}
        rowCount={rowCount}
        pageSize={isLoading ? 2 : clients?.results?.length}
        /* rowsPerPageOptions={[5]} */
        loading={isLoading}
        paginationMode="server"
        disableSelectionOnClick
        disableColumnFilter={true}
        className="overflow-x-scroll"
        components={{
          Pagination: CustomPagination,
        }}
      />
    </div>
  )
}

export default ClientWithDataGrid

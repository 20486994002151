import { hasRequestedAsProp } from "helpers/typeChecking"
import { PricingItemType, PricingTitle } from "types/PricingForm"

export enum PricingOptionActionValues {
  TOGGLE = "TOGGLE",
}

export type PricingOptionAction = {
  type: PricingOptionActionValues
  payload: {
    pricingID: string
    id: string
    value: number
  }
}

export const reducerPricingOption = (
  state: PricingItemType[],
  action: PricingOptionAction
) => {
  const { type, payload } = action
  // console.log(type, payload)
  switch (type) {
    case PricingOptionActionValues.TOGGLE: {
      // console.log(state)
      return state.map((pricingOption, _index) => {
        if (
          hasRequestedAsProp(pricingOption) &&
          pricingOption.id === payload.id
        ) {
          let newRequestState = !pricingOption.requested
          return { ...pricingOption, requested: newRequestState }
        } else {
          return pricingOption
        }
      })
    }
    default: {
      return state
    }
  }
}

import {
  EnterpriseFormDefaultValues,
  EnterpriseFormType,
} from "./EnterpriseForm"
import {
  LegalPersonFormDefaultValues,
  LegalPersonFormType,
} from "./LegalPersonForm"
import { PricingFormDefaultValues, PricingFormType } from "./PricingForm"

export type AllStepFormType =
  | EnterpriseFormType
  | LegalPersonFormType
  | PricingFormType

export const STEPS = ["Entreprise", "Représentant légal", "Forfait"]

export type StepFieldMatchNameType = {
  [P in keyof EnterpriseFormType] : string
} & {
  [P in keyof LegalPersonFormType] : string
} & {
  [P in keyof PricingFormType] : string
}

export const AllDefaultValues = [
  EnterpriseFormDefaultValues,
  LegalPersonFormDefaultValues,
  PricingFormDefaultValues,
]



import { ErrorMessage } from "@hookform/error-message"
import MuiInput from "components/common/muiInput/MuiInput"
import MuiSelect from "components/common/muiSelect/MuiSelect"
import { Controller, UseFormReturn } from "react-hook-form"
import { AllStepFormType } from "types/StepForm"
import { LegalPersonFormFieldNameEnum } from "types/LegalPersonForm"
import DragAndDrop from "../../../components/common/dragAndDrop/DragAndDrop"

export type StepLegalPersonProps = Omit<UseFormReturn<AllStepFormType>,
  "handleSubmit" | "reset">

const StepLegalPerson = (props: StepLegalPersonProps) => {
  const {
    register,
    control,
    formState: { errors },
  } = props


  return (
    <div className="bg-white grid grid-cols-8 py-8">
      <div className="col-span-6 col-start-2">
        <>
          <MuiInput
            label="Nom *"
            inputProps={{
              placeholder: "ZANNOU",
              type: "text",
              ...register(LegalPersonFormFieldNameEnum.LAST_NAME, {
                required: "Cette valeur est requise.",
              }),
            }}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            as="p"
            className="text--error"
          />
        </>

        <>
          <MuiInput
            label="Prénoms *"
            inputProps={{
              placeholder: "Mario",
              type: "text",
              ...register(LegalPersonFormFieldNameEnum.FIRST_NAME, {
                required: "Cette valeur est requise.",
              }),
            }}
          />
          <ErrorMessage
            errors={errors}
            name="first_name"
            as="p"
            className="text--error"
          />
        </>

        <>
          <MuiInput
            label="Date de naissance *"
            inputProps={{
              type: "date",
              ...register(LegalPersonFormFieldNameEnum.BIRTHDAY, {
                required: "Cette valeur est requise.",
              }),
            }}
          />
          <ErrorMessage
            errors={errors}
            name={LegalPersonFormFieldNameEnum.BIRTHDAY}
            as="p"
            className="text--error"
          />
        </>

        <>
          <Controller
            name={LegalPersonFormFieldNameEnum.NATIONALITY}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <MuiSelect
                selectProps={{ ...field, value: field.value ?? "" }}
                label="Nationalité *"
                options={[
                  { label: "France", value: "france" },
                  { label: "England", value: "england" },
                  { label: "USA", value: "usa" },
                ]}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="nationality"
            message="Cette valeur est requise"
            as="p"
            className="text--error"
          />
        </>

        <DragAndDrop inputProps={{ name: LegalPersonFormFieldNameEnum.IDENTITY_DOCUMENT }}
                     placeholders={{ whyText: "Pièce d'identité", btnText: "Mettre à jour" }}
                     errorMessage="Ajoutez une photo de la pièce d'identité du représentant" />

      </div>
    </div>
  )
}

export default StepLegalPerson

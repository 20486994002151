import { LIST_SUBSCRIPTION_URL } from "constants/api/endpoints"
import useSWR from "swr"
import { axiosFetcher, postRequest } from "./apiRequest"
import { APIDataGridResponseType } from "../types/ClientDataGrid"
import { http } from "../utils/http"

export const useClientDataGrid = (pageIndex?: number) => {
  let url = LIST_SUBSCRIPTION_URL
  if (pageIndex) {
    url += `?page=${pageIndex}`
  }

  const { data, error } = useSWR(url, axiosFetcher, { shouldRetryOnError: false })

  return {
    clients: data as APIDataGridResponseType,
    isLoading: !data && !error,
    error: error,
  }
}

export const invitationRequest = async <T, >(url: string, data?: T) => {
  try {
    let res = await postRequest(url, data)
    return {
      data: res,
    }
  } catch (error) {
    return {
      error,
    }
  }
}

export const deleteRequest = async <T, >(url: string) => {
  try {
    let res = await http.delete(url)
    return {
      data: res.data,
    }
  } catch (error) {
    return { error }
  }
}
